export class AlertMessage extends HTMLElement {
  connectedCallback () {
    this.type = this.getAttribute('type') || null
    this.closeable = this.getAttribute('closeable') || false
    if (this.type) {
      this.classList.add(`alert-${this.type}`)
    }
    this.classList.add('alert')

    if (this.closeable === true) {
      this.innerHTML += `
                <button class="alert_message--close">
                    <i class="fas fa-times"></i>
                </button>
            `
      this.querySelector('.alert_message--close').addEventListener(
        'click',
        this.onClose.bind(this)
      )
    }
  }

  static get observedAttributes () {
    return ['message']
  }

  attributeChangedCallback (name, oldValue, newValue) {
    if (name === 'message' && oldValue !== newValue) {
      if (newValue) {
        this.innerText = newValue
        this.classList.remove('hidden')
      } else {
        this.classList.add('hidden')
      }
    }
  }

  onClose (e) {
    e.preventDefault()
    this.dispatchEvent(new Event('close'))
    this.remove()
  }
}

export class FloatingAlertMessage extends AlertMessage {
  connectedCallback () {
    super.connectedCallback()
    this.classList.add('is-floating')
  }
}

export function alert (message, closeable = true) {
  const alert =
    document.querySelector('floating-alert-message') ||
    document.createElement('floating-alert-message')

  alert.setAttribute('type', 'error')
  alert.setAttribute('closeable', closeable)
  alert.innerText = message
  document.body.appendChild(alert)
}
